import React from 'react';
import InterviewItem from './InterviewItem';
import Paperwork from './Paperwork';

const InterviewContainer = ({ people = [], id, section_name }) => {
  return (
    <div className="flex flex-col items-start justify-start gap-[38px] mq1280:w-[850px]">
      {people.length > 0 ? (
        people.map((person, index) => (
          <InterviewItem 
            key={index} 
            name={person.interviewer_name} 
            date={new Date(person.interview_date + 'T00:00:00').toLocaleDateString()} 
            id={id}
            interviewid={person.id}
            section_name={section_name}
          />
        ))
      ) : (
        <p>No interviews found</p>
      )}



            <Paperwork personId={id} section_name={section_name} />
    
   

    </div>
  );
};

export default InterviewContainer;
