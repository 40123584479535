import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Custom hook to handle Google Identity Services
const useGoogleLogin = (onLogin) => {
  const navigate = useNavigate();

  console.log("useGoogleLogin hook initialized");

  const clientId =
    "373773953892-195vris1gu7kc83enn7guevb8qg7hufa.apps.googleusercontent.com";
  console.log("Client ID:", clientId);

  useEffect(() => {
    console.log("useEffect triggered");

    // Ensure the Google Identity Services script is loaded
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    console.log("Script created:", script);

    document.body.appendChild(script);
    console.log("Script appended to body");

    script.onload = () => {
      console.log("Script loaded, initializing Google Identity Services");

      // Initialize the Google Identity Services library with the provided client ID
      google.accounts.id.initialize({
        client_id: clientId,
        callback: handleCredentialResponse,
      });

      console.log("Google Identity Services initialized");
    };

    // Cleanup function to remove the script when the component unmounts
    return () => {
      console.log("Cleanup: removing script from body");
      document.body.removeChild(script);
    };
  }, [clientId]); // Depend on clientId to re-run the effect if it changes

  // Callback function to handle the response from Google Identity Services
  const handleCredentialResponse = async (response) => {
    console.log("handleCredentialResponse called with response:", response);

    const id_token = response.credential;
    console.log("ID Token received:", id_token);

    try {
      // Send this token to your server
      console.log("Sending ID token to server...");
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/google_login`,
        {
          token: id_token,
        }
      );
      console.log("Login successful", result.data);
      const { username, admin } = result.data; // Get both username and admin status

      onLogin(true, username, admin); // Pass the admin status to onLogin

      navigate("/dashboard");
    } catch (error) {
      console.error("Error logging in", error);
    }
  };

  // Function to programmatically trigger the Google sign-in flow
  const handleGoogleLogin = () => {
    console.log("handleGoogleLogin triggered, prompting Google One Tap UI");
    google.accounts.id.prompt(); // This will display the Google One Tap UI
  };

  // Return the login handler so it can be used by components
  return { handleGoogleLogin };
};

export default useGoogleLogin;
