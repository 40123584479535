import React, { useState, useEffect } from "react";
import FileDownload from "./FileDownload";
import { useTheme } from "../../themeContext";
import axios from "axios";
import { useParams } from "react-router-dom";

const InterviewDetails = ({ interviewDetails }) => {
  const { theme } = useTheme();
  const { id, interviewid } = useParams();
  const textColor = theme === "dark" ? "text-white" : "text-black";
  
  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState({
    interviewer_name: "",
    interview_date: "",
    interview_type: ""
  });

  useEffect(() => {
    if (interviewDetails) {
      setEditedDetails({
        interviewer_name: interviewDetails.interviewer_name || '',
        interview_date: interviewDetails.interview_date || '',
        interview_type: interviewDetails.interview_type || ''
      });
    }
  }, [interviewDetails]);

  const handleSave = async () => {
    try {
      const formattedDate = editedDetails.interview_date 
        ? new Date(editedDetails.interview_date).toISOString().split('T')[0]
        : '';

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/interviews/${id}/${interviewid}/update`,
        {
          interviewer_name: editedDetails.interviewer_name,
          interview_date: formattedDate,
          interview_type: editedDetails.interview_type
        },
        {
          params: {
            user_id: localStorage.getItem("username"),
          },
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.status === 200) {
        setIsEditing(false);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating interview details:", error);
      console.error("Error response:", error.response);
      alert(`Failed to update interview details: ${error.response?.data?.error || error.message}`);
    }
  };

  const handleCancel = () => {
    setEditedDetails({
      interviewer_name: interviewDetails.interviewer_name,
      interview_date: interviewDetails.interview_date,
      interview_type: interviewDetails.interview_type
    });
    setIsEditing(false);
  };

  return (
    <div>
      <div className={`absolute top-[169px] left-[276px] w-[1175px] flex flex-row items-start justify-start py-0 px-[75px] box-border max-w-full z-[2] text-13xl font-poppins ${textColor} mq1280:w-[400px]`}>
        {isEditing ? (
          <input
            type="text"
            value={editedDetails.interviewer_name}
            onChange={(e) => setEditedDetails({ ...editedDetails, interviewer_name: e.target.value })}
            className="w-full h-[22px] relative font-semibold p-1 text-black bg-white rounded"
          />
        ) : (
          <div className="h-[22px] relative font-semibold inline-block mq450:text-lgi mq850:text-7xl">
            {interviewDetails.interviewer_name}
          </div>
        )}
      </div>

      <div className="absolute top-[453px] left-[338px] text-3xl-1 leading-[34px] inline-block w-[545.8px] mq450:text-lg mq450:leading-[27px]">
        Who was the interview with?
      </div>

      <div className="absolute top-[507px] left-[334px] rounded-sm-5 bg-color-white-100 w-[1494px] flex flex-row items-start justify-start py-2 pr-[75px] pl-20 box-border max-w-full text-gray  mq1280:w-[850px]">
        {isEditing ? (
          <input
            type="text"
            value={editedDetails.interviewer_name}
            onChange={(e) => setEditedDetails({ ...editedDetails, interviewer_name: e.target.value })}
            className="flex-1 relative leading-[38px] font-semibold text-black p-1 bg-white rounded"
          />
        ) : (
          <div className="flex-1 relative leading-[38px] font-semibold inline-block mix-blend-darken max-w-full mq450:text-xl mq450:leading-[30px]">
            {interviewDetails.interviewer_name} Interview
          </div>
        )}
      </div>

      <div className="absolute top-[604px] left-[338px] text-3xl-1 leading-[34px] inline-block w-[545.8px] mq450:text-lg mq450:leading-[27px]">
        Date
      </div>

      <div className="absolute top-[653px] left-[334px] rounded-sm-5 bg-color-white-100 w-[1494px] flex flex-row items-start justify-start py-2 pr-[75px] pl-20 box-border max-w-full text-gray  mq1280:w-[850px]">
        {isEditing ? (
          <input
            type="date"
            value={editedDetails.interview_date}
            onChange={(e) => setEditedDetails({ ...editedDetails, interview_date: e.target.value })}
            className="flex-1 relative leading-[38px] font-semibold text-black p-1"
          />
        ) : (
          <div className="flex-1 relative leading-[38px] font-semibold inline-block mix-blend-darken max-w-full mq450:text-xl mq450:leading-[30px]">
            {interviewDetails.interview_date}
          </div>
        )}
      </div>

      <div className="absolute top-[750px] left-[338px] text-3xl-1 leading-[34px] inline-block w-[545.8px] mq450:text-lg mq450:leading-[27px]">
        Virtual
      </div>

      <div className="absolute top-[799px] left-[332px] rounded-sm-5 bg-color-white-100 w-[1494px] flex flex-row items-start justify-start py-2 pr-[75px] pl-20 box-border max-w-full text-gray mq1280:w-[850px]">
        {isEditing ? (
          <select
            value={editedDetails.interview_type || ''}
            onChange={(e) => {
              console.log('Selected type:', e.target.value);
              setEditedDetails({ ...editedDetails, interview_type: e.target.value });
            }}
            className="flex-1 relative leading-[38px] font-semibold text-black p-1 bg-white rounded"
          >
            <option value="">Select Type</option>
            <option value="in_person">In-Person</option>
            <option value="video">Video</option>
            <option value="phone_call">Phone Call</option>
          </select>
        ) : (
          <div className="flex-1 relative leading-[38px] font-semibold inline-block mix-blend-darken max-w-full mq450:text-xl mq450:leading-[30px]">
            {interviewDetails.interview_type === 'in_person' ? 'In-Person' :
             interviewDetails.interview_type === 'video' ? 'Video' :
             interviewDetails.interview_type === 'phone_call' ? 'Phone Call' :
             interviewDetails.interview_type}
          </div>
        )}
      </div>

      <div className="absolute top-[169px] right-[276px] flex gap-2">
        {isEditing ? (
          <>
            <button
              onClick={handleSave}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Save
            </button>
            <button
              onClick={handleCancel}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Edit
          </button>
        )}
      </div>

      {interviewDetails.audio_file && (
        <FileDownload audioFile={interviewDetails.audio_file} />
      )}
    </div>
  );
};

export default InterviewDetails;
